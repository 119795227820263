<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col sm="12" md="6">
        <h1 class="text-center">Welcome {{ username }}</h1>
      </v-col>
    </v-row>

    <v-row align="top" justify="center">
      <v-col md="4" sm="8" xs="12">
        <v-row>
          <v-col sm="6">
            <v-card
              height="100%"
              @click="routeTo('Capture')"
              :hover="true"
              :ripple="true"
              min-height="200px"
              max-height="200px"
              class="justify-center align-center w-100"
            >
              <div
                class="d-flex align-center justify-space-between"
                style="min-height: inherit;"
              >
                <v-card-text>
                  <p
                    class="text-sm-h6 text-xs-h6 text-lg-h5 text--primary text-center justify-center"
                  >
                    Capture and measure
                  </p>
                  <p class="text-center">
                    <v-icon x-large color="primary">mdi-hand</v-icon>
                  </p>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col sm="6">
            <v-card
              height="100%"
              @click="routeTo('Observe')"
              :hover="true"
              :ripple="true"
              min-height="200px"
              max-height="200px"
              class="justify-center align-center w-100"
            >
              <div
                class="d-flex align-center justify-space-between"
                style="min-height: inherit;"
              >
                <v-card-text>
                  <p
                    class="text-sm-h6 text-xs-h6 text-lg-h5 text--primary text-center justify-center"
                  >
                    Observe
                  </p>
                  <p class="text-center">
                    <v-icon x-large color="primary">mdi-binoculars</v-icon>
                  </p>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6"
            ><v-card
              @click="routeTo('Table')"
              :hover="true"
              :ripple="true"
              min-height="200px"
              max-height="200px"
              class="justify-center align-center w-100"
            >
              <div
                class="d-flex align-center justify-space-between"
                style="min-height: inherit;"
              >
                <v-card-text class="">
                  <p
                    class="text-sm-h6 text-xs-h6 text-lg-h5 text--primary text-center justify-center"
                  >
                    View and edit data
                  </p>
                  <p class="text-center">
                    <v-icon x-large color="primary">mdi-table</v-icon>
                  </p>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col sm="6">
            <v-card
              @click="showSyncDialog"
              :hover="true"
              :ripple="true"
              min-height="200px"
              max-height="200px"
              class="justify-center align-center w-100"
            >
              <div
                class="d-flex align-center justify-space-between"
                style="min-height: inherit;"
              >
                <v-card-text>
                  <p
                    class="text-sm-h6 text-xs-h6 text-lg-h5 text--primary text-center justify-center"
                  >
                    Cloud sync
                  </p>
                  <p class="text-center">
                    <v-icon x-large color="primary"
                      >mdi-database-settings</v-icon
                    >
                  </p>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from '@/store/EventBus.js'
export default {
  name: 'Home',
  data() {
    return {
      username: ''
    }
  },
  beforeMount() {
    this.checkUsername()
  },
  watch: {},

  methods: {
    showSyncDialog() {
      EventBus.$emit('show-sync-dialog')
    },
    checkUsername() {
      if (localStorage.getItem('username')) {
        this.username = localStorage.getItem('username')
        if (this.username.length < 4) {
          window.location = '/'
        } else {
          this.username = localStorage.getItem('username')
        }
      } else {
        window.location = '/'
      }
    },
    routeTo(route) {
      this.$router.push({ name: route })
    }
  }
}
</script>

<style lang="scss" scoped>
.text--primary {
  color: #4aa7bc !important;
}
</style>
